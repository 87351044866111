<template>
  <div class="sticky-side">
    <slot />
  </div>
</template>

<style>
.sticky-side {
  z-index: 401;
  @apply sticky;
}
</style>
